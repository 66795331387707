html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.application-loading {
  height: 600px;
  text-align: center;
  padding-top: 250px;
  font-weight: 400;
  font-size: 24px; }

.employer-application .contact, .employer-application .owner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px; }

.employee-application, .employer-application {
  width: 760px;
  background-color: #FFF;
  margin: 5rem auto;
  z-index: 1;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15); }
  .employee-application .error, .employer-application .error {
    color: #ff1919; }
  .employee-application .section-header, .employer-application .section-header {
    margin: 30px 0;
    font-weight: 500;
    text-align: left; }
    .employee-application .section-header.no-bold, .employer-application .section-header.no-bold {
      font-weight: 400; }
  .employee-application.termination .main-text, .employer-application.termination .main-text {
    border-bottom: none; }
  .employee-application.termination .termination-options-container, .employer-application.termination .termination-options-container {
    padding-bottom: 40px; }
  .employee-application .eligibility-group.border-bottom, .employer-application .eligibility-group.border-bottom {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
  .employee-application__success-container, .employer-application__success-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #FFF; }
    .employee-application__success-container div, .employer-application__success-container div {
      display: inherit;
      justify-content: inherit; }
    .employee-application__success-container .button, .employer-application__success-container .button {
      margin: 40px auto 30px;
      width: 240px; }
    .employee-application__success-container__checkmark, .employer-application__success-container__checkmark {
      margin-top: 40px; }
    .employee-application__success-container__header, .employer-application__success-container__header {
      font-size: 30px;
      font-weight: bold;
      padding: 20px 0; }
    .employee-application__success-container__text, .employer-application__success-container__text {
      width: 560px;
      text-align: center;
      margin: 0 auto 40px;
      font-size: 16px;
      line-height: 24px; }
  .employee-application .row, .employer-application .row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px; }
    .employee-application .row .address-autocomplete, .employer-application .row .address-autocomplete {
      width: 100%; }
    .employee-application .row .header16, .employer-application .row .header16 {
      font-size: 16px; }
      .employee-application .row .header16.error, .employer-application .row .header16.error {
        color: #ff1919; }
    .employee-application .row.margin-top, .employer-application .row.margin-top {
      margin-top: 20px; }
    .employee-application .row.three .textFieldContainer:not(:first-child), .employer-application .row.three .textFieldContainer:not(:first-child) {
      margin-left: 20px; }
    .employee-application .row.three .DateSelect, .employer-application .row.three .DateSelect {
      width: 30%; }
    .employee-application .row.three .DateSelect .textField, .employer-application .row.three .DateSelect .textField {
      width: auto; }
    .employee-application .row.radio, .employer-application .row.radio {
      display: block;
      text-align: left; }
      .employee-application .row.radio .dropdown, .employee-application .row.radio .textField, .employer-application .row.radio .dropdown, .employer-application .row.radio .textField {
        margin: 20px 0; }
    .employee-application .row .dropdown .mdc-select, .employer-application .row .dropdown .mdc-select {
      width: 560px; }
    .employee-application .row .dropdown.small.margin-left, .employer-application .row .dropdown.small.margin-left {
      margin-right: 0;
      margin-left: 20px; }
    .employee-application .row .dropdown.small .mdc-select, .employer-application .row .dropdown.small .mdc-select {
      width: 94px; }
    .employee-application .row .DateSelect, .employer-application .row .DateSelect {
      width: 100%; }
    .employee-application .row .textFieldContainer, .employer-application .row .textFieldContainer {
      width: 100%; }
      .employee-application .row .textFieldContainer.margin-left, .employer-application .row .textFieldContainer.margin-left {
        margin-left: 20px; }
      .employee-application .row .textFieldContainer.tiny, .employer-application .row .textFieldContainer.tiny {
        width: 100px;
        margin-left: 20px; }
      .employee-application .row .textFieldContainer.small, .employer-application .row .textFieldContainer.small {
        width: 120px; }
  .employee-application__container, .employer-application__container {
    width: 560px;
    padding: 40px 100px;
    color: rgba(0, 0, 0, 0.87); }
    .employee-application__container.demographic, .employer-application__container.demographic {
      padding-bottom: 0px; }
      .employee-application__container.demographic .step-personal .personal-container, .employer-application__container.demographic .step-personal .personal-container {
        margin-bottom: 0px; }
    .employee-application__container .coverage-select .no-changes, .employer-application__container .coverage-select .no-changes {
      display: flex;
      align-items: center;
      padding: 20px 0; }
    .employee-application__container .coverage-select__text, .employer-application__container .coverage-select__text {
      font-size: 20px; }
    .employee-application__container .coverage-select img, .employer-application__container .coverage-select img {
      margin-bottom: 10px; }
    .employee-application__container .step-personal .personal-container, .employer-application__container .step-personal .personal-container {
      margin-bottom: 60px; }
      .employee-application__container .step-personal .personal-container__subheader, .employer-application__container .step-personal .personal-container__subheader {
        font-size: 13px;
        margin-bottom: 20px; }
    .employee-application__container .step-personal .demographic-information .section-container, .employer-application__container .step-personal .demographic-information .section-container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .employee-application__container .step-personal .demographic-information .section, .employer-application__container .step-personal .demographic-information .section {
      text-align: left; }
      .employee-application__container .step-personal .demographic-information .section__header, .employer-application__container .step-personal .demographic-information .section__header {
        font-weight: bold;
        padding: 15px 0; }
      .employee-application__container .step-personal .demographic-information .section__subheader, .employer-application__container .step-personal .demographic-information .section__subheader {
        padding: 10px 0; }
    .employee-application__container .step-personal .demographic-information .link.arrow, .employer-application__container .step-personal .demographic-information .link.arrow {
      display: flex;
      line-height: 24px;
      height: 24px; }
      .employee-application__container .step-personal .demographic-information .link.arrow::before, .employer-application__container .step-personal .demographic-information .link.arrow::before {
        content: '';
        background: url("../../assets/images/ic-chevron-selected.svg") no-repeat left center;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        display: inline-block; }
      .employee-application__container .step-personal .demographic-information .link.arrow.open::before, .employer-application__container .step-personal .demographic-information .link.arrow.open::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-top: 7px;
        position: relative;
        left: -6px; }
    .employee-application__container .recipient-select, .employer-application__container .recipient-select {
      margin-top: 40px; }
      .employee-application__container .recipient-select__header, .employer-application__container .recipient-select__header {
        font-size: 16px;
        text-align: left; }
    .employee-application__container .step-verify .main-text, .employer-application__container .step-verify .main-text {
      border-bottom: none; }
    .employee-application__container .step-verify .signature-container, .employer-application__container .step-verify .signature-container {
      margin-top: 40px;
      text-align: center; }
      .employee-application__container .step-verify .signature-container .terms, .employer-application__container .step-verify .signature-container .terms {
        cursor: pointer;
        text-decoration: underline;
        color: #3D348B; }
    .employee-application__container .step-verify .signature, .employer-application__container .step-verify .signature {
      height: 120px;
      width: 360px;
      background-color: #F4F4F4;
      margin: 20px auto; }
    .employee-application__container .step-verify__container, .employer-application__container .step-verify__container {
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      margin-bottom: 20px; }
      .employee-application__container .step-verify__container .verify-header, .employer-application__container .step-verify__container .verify-header {
        text-align: left;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        margin: 20px;
        padding-bottom: 20px;
        font-size: 20px; }
        .employee-application__container .step-verify__container .verify-header__subheading, .employer-application__container .step-verify__container .verify-header__subheading {
          font-size: 14px; }
      .employee-application__container .step-verify__container .verify-information, .employer-application__container .step-verify__container .verify-information {
        display: flex;
        justify-content: left;
        flex-direction: row;
        margin: 20px 24px;
        text-align: left; }
        .employee-application__container .step-verify__container .verify-information__header, .employer-application__container .step-verify__container .verify-information__header {
          font-size: 14px;
          margin-bottom: 20px; }
        .employee-application__container .step-verify__container .verify-information__personal, .employee-application__container .step-verify__container .verify-information__health, .employer-application__container .step-verify__container .verify-information__personal, .employer-application__container .step-verify__container .verify-information__health {
          width: 50%;
          display: flex;
          justify-content: left;
          flex-direction: column; }
          .employee-application__container .step-verify__container .verify-information__personal ul, .employee-application__container .step-verify__container .verify-information__health ul, .employer-application__container .step-verify__container .verify-information__personal ul, .employer-application__container .step-verify__container .verify-information__health ul {
            -webkit-margin-before: 0em;
            -webkit-padding-start: 20px; }
        .employee-application__container .step-verify__container .verify-information__health, .employer-application__container .step-verify__container .verify-information__health {
          margin-left: 20px; }
          .employee-application__container .step-verify__container .verify-information__health .health-info, .employer-application__container .step-verify__container .verify-information__health .health-info {
            display: flex; }
            .employee-application__container .step-verify__container .verify-information__health .health-info.termination, .employer-application__container .step-verify__container .verify-information__health .health-info.termination {
              flex-direction: column; }
              .employee-application__container .step-verify__container .verify-information__health .health-info.termination .line-of-coverage, .employer-application__container .step-verify__container .verify-information__health .health-info.termination .line-of-coverage {
                display: flex;
                align-items: center; }
            .employee-application__container .step-verify__container .verify-information__health .health-info.padding-bottom, .employer-application__container .step-verify__container .verify-information__health .health-info.padding-bottom {
              padding-bottom: 10px; }
            .employee-application__container .step-verify__container .verify-information__health .health-info span, .employer-application__container .step-verify__container .verify-information__health .health-info span {
              line-height: 26px;
              margin-left: 10px; }
    .employee-application__container .step-verify .terms-content, .employer-application__container .step-verify .terms-content {
      padding: 20px;
      line-height: 1.4; }
      .employee-application__container .step-verify .terms-content h1, .employer-application__container .step-verify .terms-content h1 {
        margin: 1rem 0;
        font-weight: bold;
        font-size: 2rem;
        text-align: center; }
    .employee-application__container .main-text, .employer-application__container .main-text {
      margin-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      text-align: center; }
      .employee-application__container .main-text img, .employer-application__container .main-text img {
        margin-bottom: 20px; }
      .employee-application__container .main-text.border-top, .employer-application__container .main-text.border-top {
        padding-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.15); }
      .employee-application__container .main-text .name, .employer-application__container .main-text .name {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 40px;
        line-height: 36px; }
        .employee-application__container .main-text .name.light, .employer-application__container .main-text .name.light {
          font-size: 20px;
          font-weight: initial;
          margin-bottom: 20px; }
      .employee-application__container .main-text div, .employer-application__container .main-text div {
        margin: 0 30px 20px;
        line-height: 24px; }
    .employee-application__container .options, .employer-application__container .options {
      display: flex;
      justify-content: left;
      flex-wrap: wrap; }
  .employee-application__footer, .employee-application .footer, .employer-application__footer, .employer-application .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    width: 560px;
    margin: 20px auto 0; }
    .employee-application__footer.initial, .employee-application .footer.initial, .employer-application__footer.initial, .employer-application .footer.initial {
      justify-content: flex-end; }
    .employee-application__footer__error, .employee-application .footer__error, .employer-application__footer__error, .employer-application .footer__error {
      color: #ff1919;
      margin-right: 15px; }
    .employee-application__footer .steps-count, .employee-application .footer .steps-count, .employer-application__footer .steps-count, .employer-application .footer .steps-count {
      margin-left: 20px; }
    .employee-application__footer .button, .employee-application .footer .button, .employer-application__footer .button, .employer-application .footer .button {
      margin: 20px 0px 20px 0; }
  .employee-application .waive-coverage, .employer-application .waive-coverage {
    display: flex;
    justify-content: left;
    flex-direction: column;
    font-size: 16px; }
    .employee-application .waive-coverage input[type='radio'], .employer-application .waive-coverage input[type='radio'] {
      margin-right: 10px; }
    .employee-application .waive-coverage .dropdown .mdc-select, .employer-application .waive-coverage .dropdown .mdc-select {
      width: 260px; }
    .employee-application .waive-coverage span, .employer-application .waive-coverage span {
      line-height: 18px; }
    .employee-application .waive-coverage__radio-container, .employer-application .waive-coverage__radio-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px; }
    .employee-application .waive-coverage__reason, .employer-application .waive-coverage__reason {
      margin-bottom: 40px;
      display: flex;
      text-align: left;
      flex-direction: column; }
    .employee-application .waive-coverage__reason-text, .employer-application .waive-coverage__reason-text {
      margin-bottom: 20px; }
  .employee-application .select-container, .employer-application .select-container {
    display: flex;
    justify-content: left;
    flex-direction: column;
    min-width: 160px;
    max-width: 160px;
    margin: 20px 25px 20px 0;
    position: relative;
    text-align: center; }
    .employee-application .select-container__close, .employer-application .select-container__close {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer; }
    .employee-application .select-container .select, .employer-application .select-container .select {
      height: 90px;
      border: 2px solid #3D348B;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .employee-application .select-container .select input[type=radio], .employee-application .select-container .select input[type=checkbox], .employer-application .select-container .select input[type=radio], .employer-application .select-container .select input[type=checkbox] {
        margin: 10px auto; }
      .employee-application .select-container .select input[type=text], .employer-application .select-container .select input[type=text] {
        -webkit-appearance: none;
        border: 2px solid #3D348B;
        border-radius: 3px;
        padding: 8px;
        margin: 0 5px; }
    .employee-application .select-container.dependent, .employer-application .select-container.dependent {
      cursor: pointer; }
      .employee-application .select-container.dependent .select, .employer-application .select-container.dependent .select {
        border: 2px dotted #C3C3C3; }
        .employee-application .select-container.dependent .select img, .employer-application .select-container.dependent .select img {
          margin: 0px auto 10px; }
