html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.dropdown {
  margin-right: 20px; }
  .dropdown.smaller-placeholder .mdc-select .mdc-floating-label {
    font-size: 14px; }
  .dropdown.full-width {
    width: 100%;
    margin-right: 0; }
    .dropdown.full-width .mdc-select {
      width: 100%; }
  .dropdown .helperText {
    text-align: left;
    margin-top: 4px;
    margin-left: 18px;
    color: rgba(97, 97, 97, 0.87); }
    .dropdown .helperText.error {
      color: #ff1919; }
  .dropdown.error .mdc-select__native-control {
    border-bottom: 2px solid #ff1919; }
    .dropdown.error .mdc-select__native-control:hover {
      border-bottom: 2px solid #ff1919; }
  .dropdown .mdc-select {
    min-width: 160px;
    background-color: rgba(0, 0, 0, 0.02); }
    .dropdown .mdc-select__native-control {
      padding-left: 16px; }
    .dropdown .mdc-select .mdc-floating-label {
      margin-left: 16px;
      width: 100%;
      text-align: left; }
