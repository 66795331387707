html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.textFieldContainer {
  height: 72px; }
  .textFieldContainer.full-width {
    width: 100%; }
  .textFieldContainer.margin-left {
    margin-left: 20px; }
  .textFieldContainer .helperText {
    margin-top: 5px;
    margin-left: 18px;
    color: rgba(97, 97, 97, 0.87);
    text-align: left; }
    .textFieldContainer .helperText.error {
      color: #ff1919; }
  .textFieldContainer:hover .helperOnHover {
    display: block;
    position: absolute;
    font-size: 12px;
    max-width: 500px;
    padding: 10px;
    background-color: #F4F4F4; }
  .textFieldContainer .helperOnHover {
    display: none; }

.textField {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  height: 51px;
  background: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4); }
  .textField.smaller-placeholder .form-label fieldset.form-label-placeholder label {
    font-size: 10px; }
  .textField.selected {
    border-bottom: 2px solid #3D348B;
    height: 50px; }
  .textField.error {
    border-bottom-width: 2px;
    border-bottom-color: #ff1919;
    height: 50px; }
  .textField input[type='text'], .textField input[type='password'], .textField input[type='number'], .textField input[type='email'], .textField input[type='search'], .textField input[type='tel'], .textField textarea {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #212121;
    outline: 0;
    transition: all 0.2s ease-in-out;
    border: 0;
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 2;
    background: transparent; }
  .textField .form-label {
    margin-left: 16px; }
    .textField .form-label fieldset {
      position: relative;
      margin: 0;
      padding: 0;
      outline: 0;
      border: 0; }
    .textField .form-label fieldset.form-label-placeholder label {
      position: absolute;
      top: 24px;
      left: 0;
      z-index: 1;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      color: rgba(97, 97, 97, 0.87);
      -webkit-font-smoothing: antialiased; }
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox])[required]:valid, .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]):focus, .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]).hasValue, .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox])[value]:not([value='']), .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]):not(:empty), .textField .form-css-label fieldset.form-label-placeholder textarea[required]:valid, .textField .form-css-label fieldset.form-label-placeholder textarea:focus, .textField .form-css-label fieldset.form-label-placeholder textarea.hasValue, .textField .form-css-label fieldset.form-label-placeholder textarea[value]:not([value='']), .textField .form-css-label fieldset.form-label-placeholder textarea:not(:empty) {
    padding: 0; }
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]):focus, .textField .form-css-label fieldset.form-label-placeholder textarea:focus {
    outline: 0; }
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox])[required]:valid + label,
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]):focus + label,
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]).hasValue + label,
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox])[value]:not([value='']) + label,
  .textField .form-css-label fieldset.form-label-placeholder input:not([type=checkbox]):not(:empty) + label, .textField .form-css-label fieldset.form-label-placeholder textarea[required]:valid + label,
  .textField .form-css-label fieldset.form-label-placeholder textarea:focus + label,
  .textField .form-css-label fieldset.form-label-placeholder textarea.hasValue + label,
  .textField .form-css-label fieldset.form-label-placeholder textarea[value]:not([value='']) + label,
  .textField .form-css-label fieldset.form-label-placeholder textarea:not(:empty) + label {
    color: #3D348B;
    font-size: 10px;
    transform: translate3d(0, -14px, 0); }
  .textField .form-css-label label.focused {
    color: #3D348B;
    font-size: 12px;
    position: relative;
    display: block;
    margin: 0.5rem 0 0.5rem 0;
    top: 0;
    left: 0; }
  .textField.disabled {
    background-color: #F1F1F1; }
    .textField.disabled input {
      color: #88898E;
      cursor: initial; }
