html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.modal {
  z-index: 12000;
  display: flex;
  flex-direction: column;
  overflow: auto; }
  .modal a {
    text-decoration: none; }
  .modal__container {
    background: #fff;
    max-width: 1000px;
    width: 100%;
    align-self: center;
    font-size: 1.125rem;
    line-height: 1.25; }
    .modal__container.small {
      padding: 0px;
      max-width: 350px; }
    .modal__container .budget {
      width: 650px; }
      .modal__container .budget .pricing {
        width: 600px; }
    .modal__container__nav {
      display: flex;
      flex-direction: row;
      position: relative; }
      .modal__container__nav__close {
        top: 20px;
        right: 20px;
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        z-index: 1; }
        .modal__container__nav__close.small {
          width: 15px;
          height: 13px;
          margin: 5px; }
  .modal .termsContent {
    line-height: 1.4; }
    .modal .termsContent h1 {
      margin: 1rem 0;
      font-weight: bold;
      font-size: 2rem;
      text-align: center; }
    .modal .termsContent h2 {
      margin: 1rem 0;
      font-weight: 500;
      font-size: 1.5rem; }
    .modal .termsContent .savedChanges {
      margin: 0 10px 20px;
      text-align: center; }
    .modal .termsContent p {
      margin-bottom: .2rem;
      line-height: 1.1;
      font-size: 1rem; }
  .modal .detailContent .budget.simple {
    width: 900px; }
    .modal .detailContent .budget.simple .pricing {
      width: 600px; }
  .modal .detailContent .detail {
    display: none; }
    .modal .detailContent .detail.selected {
      display: block; }
  .modal .detailContent h4 {
    margin: 0 0 10px 0;
    font-size: 1.125rem;
    font-weight: 400; }
  .modal .detailContent .detailActivity {
    display: flex;
    flex-direction: column; }
    .modal .detailContent .detailActivity > .description {
      margin: 50px 0; }
    .modal .detailContent .detailActivity img {
      margin: 50px 0;
      align-self: center;
      width: 327px; }
    .modal .detailContent .detailActivity h2 {
      font-size: 30px;
      font-weight: 500; }
    .modal .detailContent .detailActivity h3 {
      font-size: 1.25rem;
      font-weight: 500;
      margin: 0 0 20px 0; }
    .modal .detailContent .detailActivity .highlights {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -25px; }
      .modal .detailContent .detailActivity .highlights .highlight {
        flex-basis: 50%; }
        .modal .detailContent .detailActivity .highlights .highlight .inner {
          margin: 20px 25px; }
          .modal .detailContent .detailActivity .highlights .highlight .inner .image {
            width: 100%;
            height: 150px;
            border-radius: 10px;
            -webkit-box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
            /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            -moz-box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
            /* Firefox 3.5 - 3.6 */
            box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
            display: block;
            background-size: cover;
            background-position: center center; }
          .modal .detailContent .detailActivity .highlights .highlight .inner .description {
            margin: 10px 0 0 0; }
            .modal .detailContent .detailActivity .highlights .highlight .inner .description p {
              margin: 0; }
          .modal .detailContent .detailActivity .highlights .highlight .inner .attribution {
            color: rgba(0, 0, 0, 0.15); }
