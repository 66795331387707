html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.eligibility-group .coverage-options {
  margin: 30px 0;
  font-weight: 500;
  text-align: left; }
  .eligibility-group .coverage-options__subtext {
    font-size: 13px;
    margin-bottom: 30px; }
  .eligibility-group .coverage-options.error {
    color: #ff1919; }

.eligibility-group .coverage-option .line-of-coverage {
  margin: 50px 0 20px;
  display: flex; }
  .eligibility-group .coverage-option .line-of-coverage__close {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px; }
  .eligibility-group .coverage-option .line-of-coverage span {
    line-height: 24px;
    margin-left: 10px; }

.eligibility-group .coverage-option .link.plus {
  display: flex;
  line-height: 24px;
  height: 24px; }
  .eligibility-group .coverage-option .link.plus::before {
    content: '';
    background: url("../../assets/images/ic_add_circle_outline_selected.svg") no-repeat left center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: inline-block; }

.eligibility-group .coverage-option .link.edit {
  display: flex;
  line-height: 24px;
  height: 24px; }
  .eligibility-group .coverage-option .link.edit::before {
    content: '';
    background: url("../../assets/images/ic_edit_selected.svg") no-repeat left center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: inline-block; }

.eligibility-group .coverage-option .plans-container {
  display: flex;
  flex-wrap: wrap; }
  .eligibility-group .coverage-option .plans-container .plans {
    display: flex;
    flex-wrap: wrap; }
    .eligibility-group .coverage-option .plans-container .plans input {
      width: inherit; }
    .eligibility-group .coverage-option .plans-container .plans div {
      width: 200px;
      margin-right: 20px; }
    .eligibility-group .coverage-option .plans-container .plans .plan {
      position: relative; }
      .eligibility-group .coverage-option .plans-container .plans .plan .textFieldContainer {
        margin-bottom: 10px; }
      .eligibility-group .coverage-option .plans-container .plans .plan img {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer; }
      .eligibility-group .coverage-option .plans-container .plans .plan .upload .file-name {
        overflow: hidden; }

.eligibility-group .coverage-option .coverage-details {
  margin: 20px 0 40px;
  display: flex; }
  .eligibility-group .coverage-option .coverage-details .textField {
    width: 200px;
    margin-right: 20px; }
