.alert {
    min-height: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__header {
        font-size: 24px;
        margin-bottom: 40px;
    }

    &__text {
        margin-bottom: 40px;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &.multiple {
            justify-content: space-around;
        }
    }

    &__error_list {
        text-align: left;
    }
}
