html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.header {
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  font-size: 24px;
  z-index: 10001;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #C3C3C3; }
  .header .back {
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 240px; }
    .header .back span {
      line-height: 24px; }
  .header .center-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    width: 44%;
    text-align: center; }
  .header .noyo {
    font-size: 40px;
    color: #3D348B;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-weight: normal; }
  .header .logo {
    text-align: left;
    width: 123px;
    height: 30px;
    margin: 15px 60px;
    flex-grow: 1;
    cursor: pointer; }
  .header .logout {
    line-height: 60px;
    height: 60px;
    float: right;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    text-align: right;
    width: 220px;
    margin-right: 40px; }
