
// Page setup
html, body {
  height: 100%;
  margin: 0;
  padding: 0;

  &.modal-open {
    overflow: hidden;
    position: relative;
  }
}

html.noscroll,
html.noscroll body { overflow: hidden; }

#root {
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  // height: 100%;

  .container {
    margin: 60px 0 0 0;
    width: 100%;
  }

  .bold {
    font-weight: bold;
  }

  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }

  a {
    color: $noyo;
    text-decoration: none;

    &:hover {
      color: $noyo;
    }
  }
}
