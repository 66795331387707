html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 100;
  border-radius: 3px;
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  /* Firefox 3.5 - 3.6 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25); }
  .autocomplete-dropdown-container .suggestion-item {
    cursor: pointer;
    background-color: #FFF;
    width: 360px;
    padding: 14px 5px;
    text-align: center; }
    .autocomplete-dropdown-container .suggestion-item--active {
      background-color: #f5f5f9;
      width: 360px;
      padding: 14px 5px;
      text-align: center;
      cursor: pointer; }
