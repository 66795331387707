// COLOR VARIABLES
$white: #FFF;
$white-30: rgba(255,255,255,0.3);

$black: #000;
$black-20: rgba(0,0,0,0.2);
$black-40: rgba(0,0,0,0.4);
$black-87: rgba(0,0,0,0.87);

$hover-gray: #C9CACB;
$dark-gray: #88898E;
$dark-gray-30: rgba(46,48,56,0.3);
$gray: #C3C3C3;
$light-gray: #F4F4F4;
$text-color: #4A4A4A;

$noyo: #3D348B;
$noyo-05: rgb(245,245,249);
$red: rgb(255, 25, 25);

$border-color: rgba(0,0,0,0.15);
$border-color2: rgba(0,0,0,0.2);

// FONT SIZING VARIABLES
$font-size-normal: 1.125rem;
$font-size-heading: 1.5rem;
$font-size-small: 0.75rem;

// FONT NAMING VARIABLES
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

