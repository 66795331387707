.checkbox {
    &.small-text {
        margin-top: 0;
        .question-text {
            font-size: 14px;
        }
    }

    .question-text {
        margin-bottom: 10px;
        font-size: 16px;
    }
}
