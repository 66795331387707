html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.login {
  text-align: center;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 558px;
  height: 476px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto; }
  .login .login-form, .login .signup-form {
    width: 340px; }
    .login .login-form .login-header, .login .signup-form .login-header {
      font-size: 30px;
      padding: 40px; }
  .login .signup-form__success-message {
    margin: 0px 50px 20px; }
  .login .ctaRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid #C3C3C3;
    margin: 0 20px;
    padding: 20px 0; }
    .login .ctaRow.center {
      justify-content: center; }
    .login .ctaRow .forgot-password {
      line-height: 36px; }
    .login .ctaRow.reset-password {
      justify-content: center;
      border-bottom: none; }
  .login .row {
    display: flex;
    justify-content: center; }
    .login .row .textField {
      margin: 0 20px 20px;
      width: 300px; }
    .login .row.terms {
      display: block;
      font-size: 14px; }
  .login .contact {
    padding: 40px 0; }
    .login .contact div {
      margin-bottom: 10px; }
