html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.broker {
  background: #FFF;
  padding: 20px 80px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto; }
  .broker .submit-application {
    padding: 40px 0;
    display: flex;
    align-items: center; }
    .broker .submit-application__error {
      color: #ff1919;
      margin-left: 18px; }
  .broker__success-container {
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .broker__success-container div {
      display: inherit;
      justify-content: inherit; }
    .broker__success-container .button {
      margin: 40px auto 30px;
      width: 240px; }
    .broker__success-container__header {
      font-size: 30px;
      font-weight: bold;
      padding: 20px 0; }
    .broker__success-container__text {
      width: 560px;
      text-align: left;
      margin: 0 auto;
      font-size: 16px;
      line-height: 24px;
      flex-direction: column; }
      .broker__success-container__text div {
        margin-bottom: 20px; }
  .broker__container {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto; }
  .broker .buttons {
    display: flex;
    justify-content: center;
    margin: 30px 0 30px; }
    .broker .buttons .button {
      margin-right: 20px;
      width: 154px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: initial; }
      .broker .buttons .button img {
        margin-right: 15px; }
      .broker .buttons .button .text {
        display: flex;
        justify-content: center;
        flex-direction: column; }
      .broker .buttons .button.selected {
        width: 152px;
        height: 44px; }
    .broker .buttons .button-subtext {
      text-align: center;
      margin-top: 10px;
      width: 162px;
      font-family: Roboto-light;
      font-size: 13px;
      line-height: 20px; }
  .broker .row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px; }
    .broker .row .address-autocomplete {
      width: inherit; }
    .broker .row.bold {
      font-weight: 500; }
    .broker .row.three .textFieldContainer:last-child .textField {
      margin-right: 0px;
      width: 250px; }
    .broker .row.three .textField {
      width: 230px; }
    .broker .row.spacer {
      padding-bottom: 40px;
      border-bottom: 1px solid #F4F4F4; }
    .broker .row .textField {
      margin: 0 20px 0 0;
      width: 300px; }
      .broker .row .textField.full-width {
        width: 100%; }
  .broker__application-employer .error {
    color: #ff1919; }
  .broker__application-employer .cobra-employee
.initial-header {
    margin-bottom: 20px;
    text-align: center; }
  .broker__application-employer .subheader {
    margin-bottom: 10px; }
  .broker__application-employer .section-header {
    margin: 30px 0;
    font-weight: 500;
    text-align: left; }
  .broker__application-employer .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    min-width: 560px;
    margin: 20px auto 0; }
    .broker__application-employer .footer.initial {
      justify-content: flex-end; }
    .broker__application-employer .footer__error {
      color: #ff1919;
      margin-right: 15px; }
    .broker__application-employer .footer .steps-count {
      margin-left: 20px; }
    .broker__application-employer .footer .button {
      margin: 20px 0px 20px 0; }
  .broker__application-employer .main-header-container {
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center; }
    .broker__application-employer .main-header-container.border-top {
      padding-top: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.15); }
    .broker__application-employer .main-header-container .text {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 40px;
      line-height: 36px; }
      .broker__application-employer .main-header-container .text.light {
        font-size: 20px;
        font-weight: initial;
        margin-bottom: 20px; }
    .broker__application-employer .main-header-container div {
      margin: 0 30px 20px;
      line-height: 24px; }
  .broker__application-employer .signoff .signature-container {
    margin: 40px 0;
    text-align: center; }
    .broker__application-employer .signoff .signature-container .signature {
      height: 120px;
      width: 360px;
      background-color: #F4F4F4;
      margin: 20px auto; }
    .broker__application-employer .signoff .signature-container .terms {
      cursor: pointer;
      text-decoration: underline;
      color: #3D348B; }
  .broker__application-employer .eligibility-group-employer.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    padding-top: 20px; }
  .broker__application-employer .eligibility-group-employer .close {
    cursor: pointer;
    margin-left: 10px;
    margin-top: 5px; }
  .broker__application-employer .coverage-option-employer.open, .broker__application-employer .prior-coverage-option-employer.open, .broker__application-employer .other-coverage-information.open {
    padding-bottom: 20px;
    border-bottom: 1px solid #F4F4F4; }
  .broker__application-employer .coverage-option-employer .line-of-coverage, .broker__application-employer .prior-coverage-option-employer .line-of-coverage, .broker__application-employer .other-coverage-information .line-of-coverage {
    margin: 50px 0 20px;
    display: flex; }
    .broker__application-employer .coverage-option-employer .line-of-coverage__close, .broker__application-employer .prior-coverage-option-employer .line-of-coverage__close, .broker__application-employer .other-coverage-information .line-of-coverage__close {
      cursor: pointer;
      margin-left: 10px;
      margin-top: 5px; }
    .broker__application-employer .coverage-option-employer .line-of-coverage span, .broker__application-employer .prior-coverage-option-employer .line-of-coverage span, .broker__application-employer .other-coverage-information .line-of-coverage span {
      line-height: 24px;
      margin-left: 10px; }
  .broker__application-employer .coverage-option-employer .link.plus, .broker__application-employer .prior-coverage-option-employer .link.plus, .broker__application-employer .other-coverage-information .link.plus {
    display: flex;
    line-height: 24px;
    height: 24px; }
    .broker__application-employer .coverage-option-employer .link.plus::before, .broker__application-employer .prior-coverage-option-employer .link.plus::before, .broker__application-employer .other-coverage-information .link.plus::before {
      content: '';
      background: url("../../assets/images/ic_add_circle_outline_selected.svg") no-repeat left center;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      display: inline-block; }
  .broker__application-employer .coverage-option-employer .link.edit, .broker__application-employer .prior-coverage-option-employer .link.edit, .broker__application-employer .other-coverage-information .link.edit {
    display: flex;
    line-height: 24px;
    height: 24px; }
    .broker__application-employer .coverage-option-employer .link.edit::before, .broker__application-employer .prior-coverage-option-employer .link.edit::before, .broker__application-employer .other-coverage-information .link.edit::before {
      content: '';
      background: url("../../assets/images/ic_edit_selected.svg") no-repeat left center;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      display: inline-block; }
  .broker__application-employer .coverage-option-employer .plans-container, .broker__application-employer .prior-coverage-option-employer .plans-container, .broker__application-employer .other-coverage-information .plans-container {
    display: flex;
    flex-wrap: wrap; }
    .broker__application-employer .coverage-option-employer .plans-container .plans, .broker__application-employer .prior-coverage-option-employer .plans-container .plans, .broker__application-employer .other-coverage-information .plans-container .plans {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .broker__application-employer .coverage-option-employer .plans-container .plans input, .broker__application-employer .prior-coverage-option-employer .plans-container .plans input, .broker__application-employer .other-coverage-information .plans-container .plans input {
        width: inherit; }
      .broker__application-employer .coverage-option-employer .plans-container .plans .plan, .broker__application-employer .prior-coverage-option-employer .plans-container .plans .plan, .broker__application-employer .other-coverage-information .plans-container .plans .plan {
        margin-bottom: 20px;
        position: relative; }
        .broker__application-employer .coverage-option-employer .plans-container .plans .plan .row.three, .broker__application-employer .prior-coverage-option-employer .plans-container .plans .plan .row.three, .broker__application-employer .other-coverage-information .plans-container .plans .plan .row.three {
          margin-bottom: 0px; }
        .broker__application-employer .coverage-option-employer .plans-container .plans .plan .textFieldContainer, .broker__application-employer .prior-coverage-option-employer .plans-container .plans .plan .textFieldContainer, .broker__application-employer .other-coverage-information .plans-container .plans .plan .textFieldContainer {
          margin-bottom: 10px; }
        .broker__application-employer .coverage-option-employer .plans-container .plans .plan img, .broker__application-employer .prior-coverage-option-employer .plans-container .plans .plan img, .broker__application-employer .other-coverage-information .plans-container .plans .plan img {
          cursor: pointer;
          margin-left: 10px;
          margin-top: 3px; }
  .broker__application-employer .coverage-option-employer .coverage-details, .broker__application-employer .prior-coverage-option-employer .coverage-details, .broker__application-employer .other-coverage-information .coverage-details {
    margin: 20px 0 40px;
    display: flex; }
    .broker__application-employer .coverage-option-employer .coverage-details .textField, .broker__application-employer .prior-coverage-option-employer .coverage-details .textField, .broker__application-employer .other-coverage-information .coverage-details .textField {
      width: 200px;
      margin-right: 20px; }
  .broker__application-employer .coverage-option-employer .cobra-employees, .broker__application-employer .coverage-option-employer .terminated-employees, .broker__application-employer .prior-coverage-option-employer .cobra-employees, .broker__application-employer .prior-coverage-option-employer .terminated-employees, .broker__application-employer .other-coverage-information .cobra-employees, .broker__application-employer .other-coverage-information .terminated-employees {
    margin-bottom: 20px; }
    .broker__application-employer .coverage-option-employer .cobra-employees .cobra-employee:first-child, .broker__application-employer .coverage-option-employer .terminated-employees .cobra-employee:first-child, .broker__application-employer .prior-coverage-option-employer .cobra-employees .cobra-employee:first-child, .broker__application-employer .prior-coverage-option-employer .terminated-employees .cobra-employee:first-child, .broker__application-employer .other-coverage-information .cobra-employees .cobra-employee:first-child, .broker__application-employer .other-coverage-information .terminated-employees .cobra-employee:first-child {
      margin-top: 10px; }
  .broker__application-employer .coverage-option-employer .cobra-employee, .broker__application-employer .coverage-option-employer .terminated-employee, .broker__application-employer .prior-coverage-option-employer .cobra-employee, .broker__application-employer .prior-coverage-option-employer .terminated-employee, .broker__application-employer .other-coverage-information .cobra-employee, .broker__application-employer .other-coverage-information .terminated-employee {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px; }
