.dropdown-menu {
    &.open {
        .mdc-menu-selected {
            &__arrow {
                background: url('../../../assets/images/ic_arrow_drop_down_selected.svg') no-repeat left center;
            }
        }

        .mdc-menu {
            opacity: 1;
            display: initial;
        }
    }

    .mdc-menu-selected {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__arrow {
            content: '';
            background: url('../../../assets/images/ic_arrow_drop_down.svg') no-repeat left center;
            width: 24px;
            height: 24px;
        }
    }
}
