html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.home {
  display: flex;
  flex-direction: column; }
  .home__footer {
    position: fixed;
    background: white;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    height: 60px;
    border-top: 1px solid #C3C3C3; }
    .home__footer .footer-right {
      display: flex;
      height: inherit;
      align-items: center;
      justify-content: flex-end;
      padding-right: 60px; }
      .home__footer .footer-right .link.arrow {
        color: #88898E;
        display: flex;
        line-height: 20px;
        height: 20px;
        margin-top: 0;
        margin-left: 10px; }
        .home__footer .footer-right .link.arrow::before {
          content: '';
          background: url("../../../assets/images/ic-chevron-selected.svg") no-repeat left center;
          width: 20px;
          height: 20px;
          margin-right: 5px;
          display: inline-block; }
        .home__footer .footer-right .link.arrow.left::before {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
          position: relative; }
      .home__footer .footer-right a {
        height: 20px; }
      .home__footer .footer-right img {
        margin-left: 5px; }
      .home__footer .footer-right .pagination {
        display: flex;
        width: 135px;
        padding-left: 10px;
        color: #88898E;
        height: 20px;
        align-items: center; }
  .home .nav {
    display: flex;
    flex-direction: row;
    padding: 20px 60px 0 60px;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
    .home .nav .tabs {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      margin-right: 1em; }
      .home .nav .tabs .tab {
        height: 50px;
        width: 125px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        color: #616161;
        line-height: 50px;
        text-align: center;
        cursor: pointer; }
        .home .nav .tabs .tab.active {
          margin-top: -1px;
          color: #3D348B;
          background-color: #FFF;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 5px 5px 0 0;
          border-bottom: 1px solid #fff; }
    .home .nav .button {
      margin-top: 0;
      width: 160px;
      height: 36px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .home .nav .button.filled.selected .text {
        font-weight: bold; }
      .home .nav .button img {
        margin-top: 2px; }
      .home .nav .button .text {
        font-size: 14px;
        height: 36px;
        line-height: 36px; }
  .home .content {
    width: 100%;
    overflow-x: scroll;
    min-height: 800px;
    padding-bottom: 1rem; }
    .home .content table {
      width: 2900px;
      border-spacing: 0; }
      .home .content table thead {
        height: 60px;
        background: #fff; }
        .home .content table thead th {
          color: #616161;
          font-weight: 500;
          height: 60px;
          padding: 0 8px;
          border: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          text-align: left; }
      .home .content table tbody tr {
        background: #FFF; }
        .home .content table tbody tr td {
          height: 48px;
          padding: 0 8px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
          .home .content table tbody tr td.center {
            text-align: center; }
          .home .content table tbody tr td.notes {
            width: 200px; }
          .home .content table tbody tr td.priority {
            width: 135px; }
          .home .content table tbody tr td input {
            -webkit-appearance: none;
            border: none;
            outline: none;
            height: 100%;
            font-size: 14px;
            margin-right: 4px; }
            .home .content table tbody tr td input:focus {
              border: 2px solid #3D348B;
              border-radius: 3px;
              margin-right: 0px; }
  .home .filter-container {
    position: relative;
    margin-right: 20px; }
    .home .filter-container .filterIcon {
      width: 16px;
      height: 16px;
      top: 10px;
      left: 10px;
      position: absolute; }
    .home .filter-container .filter {
      outline: none;
      height: 36px;
      line-height: 36px;
      font-family: 'Roboto';
      padding: 0 4px;
      font-size: 13px;
      border-radius: 3px;
      border: 1px solid #B5B5B5;
      padding: 1px 6px 0 36px; }
      .home .filter-container .filter:focus {
        border: 2px solid #3D348B;
        padding-top: 0px;
        padding-right: 4px; }
  .home .transaction-row.empty {
    height: 60px; }
  .home .transaction-row .line-of-coverage-container {
    display: flex;
    align-items: center; }
    .home .transaction-row .line-of-coverage-container span {
      margin-left: 18px; }
  .home .transaction-row .launch-link:after {
    content: '';
    background: url("../../../assets/images/ic_open_in_new_selected.svg") no-repeat left center;
    width: 24px;
    height: 24px; }
  .home .transaction-row .priority-container {
    display: flex;
    align-items: center;
    margin: 0 12px;
    cursor: pointer; }
    .home .transaction-row .priority-container__bell {
      content: '';
      background: url("../../../assets/images/ic-alert-selected.svg") no-repeat left center;
      width: 24px;
      height: 24px; }
    .home .transaction-row .priority-container__text {
      margin-left: 10px; }
  .home .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer; }
    .home .status .circle {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      background: #E2E2E2;
      margin-right: 18px;
      margin-left: 3px; }
    .home .status span {
      font-size: 14px; }
    .home .status.FULFILLMENT_SUBMITTED_TO_CARRIER .circle {
      background: #FF19A0; }
    .home .status.FULFILLMENT_FOLLOWED_UP_WITH_CARRIER .circle {
      background: #F7BA00; }
    .home .status.FULFILLMENT_FOLLOWING_UP_WITH_CLIENT .circle {
      background: #FF8811; }
    .home .status.FULFILLMENT_CONFIRMED_BY_CARRIER .circle {
      background: #0054A3; }
    .home .status.FULFILLMENT_CONFIRMED_WITH_CLIENT .circle {
      background: #0054A3; }
    .home .status.FULFILLMENT_COMPLETED .circle {
      background: url("../../../assets/images/completed.svg");
      background-size: 18px 18px; }
    .home .status.INTERNAL_ERROR .circle {
      background: #FF0000; }
