html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.DateSelect {
  display: flex;
  flex-direction: column;
  position: relative; }
  .DateSelect.full-width {
    width: 100%; }
    .DateSelect.full-width .textFieldContainer .textField {
      width: 100%; }
  .DateSelect input {
    cursor: pointer; }
  .DateSelect .picker {
    display: none;
    background: #fff;
    z-index: 10002;
    line-height: 33px;
    position: absolute;
    flex-direction: column;
    font-size: 14px;
    padding: 10px;
    width: 340px;
    margin-top: 62px;
    background: #fff;
    /* For browsers that do not support gradients */
    color: #4A4A4A;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
    /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
    /* Firefox 3.5 - 3.6 */
    box-shadow: 0px 2px 4px 0px rgba(43, 61, 85, 0.4);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .DateSelect .picker.top {
      bottom: 82px; }
    .DateSelect .picker .nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px; }
      .DateSelect .picker .nav .prev, .DateSelect .picker .nav .next {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer; }
        .DateSelect .picker .nav .prev.hide, .DateSelect .picker .nav .next.hide {
          background: none;
          cursor: default;
          opacity: 0.2; }
      .DateSelect .picker .nav .prev {
        background-image: '/prev.svg'; }
      .DateSelect .picker .nav .month {
        text-align: center;
        flex-grow: 1.0;
        line-height: 20px;
        margin-left: 50px; }
      .DateSelect .picker .nav .next {
        background-image: '/next.svg'; }
    .DateSelect .picker .weekdays {
      display: flex;
      flex-direction: row; }
      .DateSelect .picker .weekdays .weekday {
        flex: 1;
        text-align: center; }
    .DateSelect .picker .weeks {
      display: flex;
      flex-direction: column; }
      .DateSelect .picker .weeks .week {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        margin-bottom: 3px; }
        .DateSelect .picker .weeks .week .day {
          flex: 1;
          text-align: center;
          cursor: pointer;
          max-width: 33px; }
          .DateSelect .picker .weeks .week .day.hidden {
            cursor: default;
            color: rgba(0, 0, 0, 0.15); }
            .DateSelect .picker .weeks .week .day.hidden span {
              display: none; }
          .DateSelect .picker .weeks .week .day:hover {
            background: #C9CACB;
            border-radius: 20px; }
          .DateSelect .picker .weeks .week .day.hidden:hover {
            background: none;
            border-radius: 0; }
          .DateSelect .picker .weeks .week .day.disabled {
            color: rgba(0, 0, 0, 0.15);
            cursor: default; }
          .DateSelect .picker .weeks .week .day.disabled:hover {
            background: none;
            border-radius: 0; }
          .DateSelect .picker .weeks .week .day.selected {
            background: #3D348B;
            color: #fff;
            border-radius: 20px; }
    .DateSelect .picker.display {
      display: flex; }
