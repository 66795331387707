html, body {
  height: 100%;
  margin: 0;
  padding: 0; }
  html.modal-open, body.modal-open {
    overflow: hidden;
    position: relative; }

html.noscroll,
html.noscroll body {
  overflow: hidden; }

#root {
  height: 100%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.app {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  z-index: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px; }
  .app .container {
    margin: 60px 0 0 0;
    width: 100%; }
  .app .bold {
    font-weight: bold; }
  .app .no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .app a {
    color: #3D348B;
    text-decoration: none; }
    .app a:hover {
      color: #3D348B; }

.button {
  padding: 2px;
  color: #000;
  border-radius: 3px;
  border: 1px solid #000;
  width: 100px;
  height: 28px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s; }
  .button img {
    margin-right: 15px; }
  .button.lowercase {
    text-transform: initial; }
  .button.disabled {
    cursor: not-allowed;
    opacity: 0.5; }
  .button.double {
    width: 200px; }
  .button .text {
    font-size: 14px;
    line-height: 30px; }
  .button.white {
    border: 1px solid #88898E;
    color: rgba(0, 0, 0, 0.87);
    background-color: #FFF;
    transition: opacity 0.4s ease-in-out; }
    .button.white.selected {
      border: 1px solid #3D348B;
      color: rgba(0, 0, 0, 0.87); }
      .button.white.selected:hover {
        background-color: none; }
    .button.white:hover {
      transition: opacity 0.4s ease-in-out;
      opacity: 0.8; }
  .button.gray {
    border: 1px solid #88898E;
    color: #88898E;
    padding: 3px; }
    .button.gray:hover {
      transition: background-color 0.5s, color 0.5s;
      background-color: rgba(46, 48, 56, 0.3);
      color: #FFF; }
  .button.selected {
    border: 2px solid #3D348B;
    color: #000;
    background-color: #FFF; }
    .button.selected:hover {
      background-color: #3D348B;
      transition: background-color 0.5s;
      color: #FFF; }
      .button.selected:hover.disabled {
        color: #fff; }
  .button.filled {
    border: 2px solid #3D348B;
    color: #FFF;
    background-color: #3D348B;
    transition: opacity 0.4s ease-in-out; }
    .button.filled:hover {
      transition: opacity 0.4s ease-in-out;
      opacity: 0.8; }
