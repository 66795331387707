.radio-button {
    margin: 0;

    &.margin-left {
        margin-left: 20px;
    }

    &.small-text {
        margin-top: 0;
        .question-text {
            font-size: 14px;
        }
    }

    .radio {
        margin-right: 20px;
    }

    .question-text {
        margin-bottom: 10px;
        font-size: 16px;
    }

    .questions {
        display: flex;
    }

    .display {
        text-transform: capitalize;
    }

    &__container, input[type='radio'] {
        margin-right: 10px;
    }
}
